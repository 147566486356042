import React, {useEffect, useState} from "react";
import {Accordion, Panel} from 'baseui/accordion';
import { toaster, ToasterContainer } from 'baseui/toast';
import Loading from "./loading";
import moment from "moment";

export const PaidPaymentHistory = ({googleID}) => {
  const [loading, setLoading] = useState(true);
    const [paymentRecords, setPaymentRecords] = useState([]);

    useEffect(() => {
      fetch(`https://xcro-343606.uw.r.appspot.com/paidPaymentHistory?googleID=${googleID}`)
        .then(function (response) {
          // The response is a Response instance.
          // You parse the data into a useable format using `.json()`
          setLoading(false);
          return response.json();
        }).then(
          (result) => {
            setPaymentRecords(result.PaymentRecords);
          },
          () => {
            const errorMessage = (
              <div>
                  <p>Failed to load your payment history.</p>
                  <p>Please try again.</p>
              </div>
            )
            toaster.negative(errorMessage);
          }
        )
    }, [googleID])

    const getPaymentType = (oaymentType) => {
        switch (oaymentType) {
            case "showingFee":
                return "Showing Fee";
            case "inspectionFee":
                return "Inspection Fee";
            case "offerFee":
                return "Offer Fee";
            default:
                return "Unknown Fee";
        }
    }

    let page;

    if (loading) {
        page = <Loading />;
    } else if (paymentRecords.length === 0) {
      page = "No payment records";
    } else {
      let records = []; 
      for (let i = 0; i < paymentRecords.length; i++) {
        const date = moment.unix(paymentRecords[i].timestamp).format("MM/DD/YYYY");
        const title = `(${date}) To: ${paymentRecords[i].paymentEmail}`;
        let content = (
            <div>
                <p>{getPaymentType(paymentRecords[i].paymentType)}: ${paymentRecords[i].paymentAmount}</p>
                <p>{paymentRecords[i].note ? "Note: " + paymentRecords[i].note : ""}</p>
            </div>
        );
        records.push(<Panel title={title}>{content}</Panel>);
      }
        page = (
          <Accordion
            overrides={{
              Content: {
                style: ({ $theme }) => ({
                  outline: `${$theme.colors.primary200} solid`,
                  backgroundColor: $theme.colors.primary100,
                  textAlign: "left",
                  paddingTop: "0",
                  paddingBottom: "0"
                })
              },
              ContentAnimationContainer: {
                style: ({ $theme }) => ({
                  outline: `${$theme.colors.primary200} solid`,
                  backgroundColor: $theme.colors.primary100
                })
              },
              Header: {
                style: ({ $theme }) => ({
                  outline: `${$theme.colors.primary200} solid`,
                  backgroundColor: $theme.colors.primary100
                })
              },
              PanelContainer: {
                style: ({ $theme }) => ({
                  outline: `${$theme.colors.primary200} solid`,
                  backgroundColor: $theme.colors.primary100
                })
              }
            }}
          >
            {records}
          </Accordion>
        );
    }
    return (
      <div className="panel">
        <ToasterContainer />
        {page}
      </div>
    );
}