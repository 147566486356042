import React from "react";
import { styled } from "baseui";
import { Block } from 'baseui/block';
import { StyledSpinnerNext as Spinner } from "baseui/spinner";

const Wrapper = styled(Block, {
    display: 'flex',
    justifyContent: 'center',
    margin: '32px 0 48px 0',
});

const StyledSpinner = styled(Spinner, ({ $theme}) => ({
    borderTopColor: $theme.colors.primary,
}));

export default function Loading() {
    return (
        <Wrapper>
            <StyledSpinner />
        </Wrapper>
    );
}