import React, {useState} from "react";
import { PaidPaymentHistory } from "./PaidPaymentHistory";
import { ReceivedPaymentHistory } from "./ReceivedPaymentHistory";
import { Tabs, Tab } from "baseui/tabs";

export const PaymentHistory = ({googleID}) => {
  const [activeKey, setActiveKey] = React.useState("0");

    return (
      <div className="panel">
        <Tabs
          activeKey={activeKey}
          onChange={({ activeKey }) => {
            setActiveKey(activeKey);
          }}
          //fill={FILL.fixed}
          //activateOnFocus
          overrides={{
            Tab: {
              style: ({ $theme }) => ({
                flex: "1"
              })
            }
          }}
        >
          <Tab title="Paid">
            <PaidPaymentHistory googleID={googleID} />
          </Tab>
          <Tab title="Received">
            <ReceivedPaymentHistory googleID={googleID} />
          </Tab>
        </Tabs>
      </div>
    );
}