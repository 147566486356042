import React from "react";

export const HomePage = () => {
    return (
      <div className="panel left-text">
        <div className="title-font">How it works</div>
        <div>For Agents</div>
        <p className="padded-text small-font">1. Enter your email for final settlement payments in Account settings.</p>
        <p className="padded-text small-font">2. Prompt your client to make payments after each payable work item.</p>
        <div >For Buyers</div>
        <p className="padded-text small-font">1. Enter your email for final settlement payments in Account settings.</p>
        <p className="padded-text small-font">2. Make Xcro payment after each payable work item for your agent.</p>
        <div >Note</div>
        <div className="padded-text small-font">1. Xcro will hold all the payments before final settlement</div>
        <div className="padded-text small-font">2. Agents will get payment if clients decide to terminate working agreement</div>
        <div className="padded-text small-font">3. Clients will get refunds for the Xcro deposit if a transaction successful closes</div>
      </div>
    );
}