import './App.css';
import { PaymentHistory } from './components/PaymentHistory';
import { Payment } from './components/payment';
import { Account } from './components/account';
import { HomePage } from './components/homepage';
import React, { useState } from "react";

import { GoogleLogin, GoogleLogout } from 'react-google-login';

//import { Tabs, Tab, FILL } from "baseui/tabs-motion";
import { Tabs, Tab } from "baseui/tabs";

function App() {
  const [user, setUser] = useState(null);
  const [activeKey, setActiveKey] = React.useState("0");

  const googleLoginSuccess = (response) => {
    console.log(response);
    setUser(response);
  }

  const googleLoginFailure = (err) => {
    console.log("failed to login");
    console.log(err);
  }

  const googleLogoutSuccess = () => {
    setUser(null);
  }

  let googleButton;
  if (user == null) {
    googleButton = <GoogleLogin
      clientId="701739183479-fi8bjresq5flj06fbeqgdgb6q2ce9nb0.apps.googleusercontent.com"
      onSuccess={googleLoginSuccess}
      onFailure={googleLoginFailure}
      cookiePolicy={'single_host_origin'}
      isSignedIn={true}
    />;
  } else {
    googleButton = <GoogleLogout
      clientId="701739183479-fi8bjresq5flj06fbeqgdgb6q2ce9nb0.apps.googleusercontent.com"
      onLogoutSuccess={googleLogoutSuccess}
      buttonText="Logout"
    />;
  }

  let page;
  if (user == null) {
    page = "Home";
  } else {
    page = (
    <Tabs
      activeKey={activeKey}
      onChange={({ activeKey }) => {
        setActiveKey(activeKey);
      }}
      //fill={FILL.fixed}
      //activateOnFocus
      overrides={{
        Tab: {
          style: ({ $theme }) => ({
            flex: "1"
          })
        }
      }}
    >
      <Tab title="Home">
        <HomePage />
      </Tab>
      <Tab title="Pay">
        <Payment googleID={user.googleId} userEmail={user.profileObj.email} />
      </Tab>
      <Tab title="Payments">
        <PaymentHistory  googleID={user.googleId} />
      </Tab>
      <Tab title="Account">
        <Account googleID={user.googleId} userEmail={user.profileObj.email}/>
      </Tab>
    </Tabs>
    );
  }


  return (
    <div className="App">
      <header className='app-header'>
        <div className='header-item logo'>Xcro</div>
        <div className='header-item'>
          {googleButton}
        </div>
      </header>
      <div className='app-content'>
        {page}
      </div>
    </div>
  );
}

export default App;
