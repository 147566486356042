import React, { useEffect, useState, useCallback } from "react";
import { Input } from "baseui/input";
import { Button } from "baseui/button";
import {
    Checkbox,
    LABEL_PLACEMENT
} from "baseui/checkbox";
import { toaster, ToasterContainer } from 'baseui/toast';
import Loading from "./loading";

export const Account = ({ googleID, userEmail }) => {
    const [loading, setLoading] = useState(true);
    const [paymentEmail, setPaymentEmail] = useState(null);
    const [newEmail, setNewEmail] = useState(null);
    const [isAgent, setIsAgent] = useState(false);
    const [showingFee, setShowingFee] = useState(0);
    const [inspectionFee, setInspectionFee] = useState(0);
    const [offerFee, setOfferFee] = useState(0);

    useEffect(() => {
        fetch(`https://xcro-343606.uw.r.appspot.com/account?googleID=${googleID}`)
            .then(function (response) {
                // The response is a Response instance.
                // You parse the data into a useable format using `.json()`
                setLoading(false);
                return response.json();
            }).then(
                (result) => {
                    if (result.paymentEmail) {
                        setPaymentEmail(result.paymentEmail);
                        setIsAgent(result.isAgent);
                        setShowingFee(result.showingFee);
                        setInspectionFee(result.inspectionFee);
                        setOfferFee(result.offerFee);
                    }
                },
                () => {
                    const errorMessage = (
                        <div>
                            <p>Failed to load your account.</p>
                            <p>Please try again.</p>
                        </div>
                    )
                    toaster.negative(errorMessage)
                }
            )
    }, [])

    const updateAccount = useCallback(() => {
        //alert(newEmail);

        const sFee = Number(showingFee)
        if (isNaN(sFee)) {
            return;
        }

        const iFee = Number(inspectionFee)
        if (isNaN(iFee)) {
            return;
        }

        const oFee = Number(offerFee)
        if (isNaN(oFee)) {
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "googleID": googleID,
                "userEmail": userEmail,
                "paymentEmail": newEmail ? newEmail : paymentEmail,
                "isAgent": isAgent,
                "showingFee": sFee,
                "inspectionFee": iFee,
                "offerFee": oFee
            })
        };
        fetch('https://xcro-343606.uw.r.appspot.com/account', requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result) => {
                toaster.info('Successfully updated your account.')
                setPaymentEmail(result.paymentEmail);
            },
            () => {
                const errorMessage = (
                    <div>
                        <p>Failed to update your account.</p>
                        <p>Please try again.</p>
                    </div>
                )
                toaster.negative(errorMessage)
            });
    }, [newEmail, isAgent, showingFee, inspectionFee, offerFee]);

    /*
    const fortmatResponse = (res) => {
        return JSON.stringify(res, null, 2);
      }

    async function saveEmail() {
        const postData = {
            "googleID": googleID,
            "paymentEmail": newEmail 
        };
        axios.post('https://xcro-343606.uw.r.appspot.com/account', postData)
            .then((result) => {
                console.log(result);
              },
              (error) => {
                  console.log(error);
              });
      }
      */

    const validEmail = () => {
        if (newEmail) {
            return newEmail.includes("@");
        }

        return true;
    }

    const validAgentInput = () => {
        if (isAgent) {
            const sFee = Number(showingFee)
            if (isNaN(sFee)) {
                return false;
            }

            const iFee = Number(inspectionFee)
            if (isNaN(iFee)) {
                return false;
            }

            const oFee = Number(offerFee)
            if (isNaN(oFee)) {
                return false;
            }
        }

        return true;
    }
    const validAccountInfo = useCallback(() => {
        return validEmail() && validAgentInput();
    });

    let agentSection;
    if (!isAgent) {
        agentSection = null;
    } else {
        agentSection = (
            <div className="left-text">
                <p>Fee for each showing</p>
                <Input
                    value={showingFee}
                    className="input"
                    onChange={e => setShowingFee(e.target.value)}
                    placeholder="* 0 if not set..."
                />
                <p>Fee for each inspection visit</p>
                <Input
                    value={inspectionFee}
                    className="input"
                    onChange={e => setInspectionFee(e.target.value)}
                    placeholder="* 0 if not set..."
                />
                <p>Fee for working on each offer</p>
                <Input
                    value={offerFee}
                    className="input"
                    onChange={e => setOfferFee(e.target.value)}
                    placeholder="* 0 if not set..."
                />
            </div>
        );
    }

    let page;
    if (loading) {
        page = <Loading />;
    } else {
        let message;
        let buttonText;
        if (paymentEmail) {
            buttonText = "Update";
            message = (
                <div className="left-text">
                    <p>Your final settlement payment email:</p>
                    <div className="padded-text">{paymentEmail}</div>
                    <p>In final settlement:</p>
                    <div className="padded-text small-font">Agents will get payment if clients decide to terminate working agreement</div>
                    <div className="padded-text small-font">Clients will get refunds for the Xcro deposit if a transaction successful closes</div>
                </div>
            );
        }
        else {
            buttonText = "Save";
            message = (
                <div className="left-text">
                    <p>Please enter the email for your PayPal account to receive final settlement payments</p>
                    <div className="padded-text small-font">Agents will get payment if clients decide to terminate working agreement</div>
                    <div className="padded-text small-font">Clients will get refunds for the Xcro deposit if a transaction successful closes</div>
                </div>
            );
        }


        page = (
            <div>
                {message}
                <Input
                    className="input"
                    onChange={e => setNewEmail(e.target.value)}
                    placeholder="Enter an email..."
                />
                <Checkbox
                    checked={isAgent}
                    onChange={e => setIsAgent(e.target.checked)}
                    labelPlacement={LABEL_PLACEMENT.right}
                    overrides={{
                        Root: {
                            style: ({ $theme }) => ({
                                marginTop: '30px'
                            })
                        },
                        Label: {
                            style: ({ $theme }) => ({
                                color: 'white',
                                fontWeight: 'normal'
                            })
                        }
                    }}
                >
                    Are you an real estate agent?
                </Checkbox>
                {agentSection}
                <Button className="button" onClick={updateAccount} disabled={!validAccountInfo()}>{buttonText}</Button>
            </div>);
    }

    return (
        <div className="panel">
            <ToasterContainer />
            {page}
        </div>
    );
}