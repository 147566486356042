import React, { useState, useEffect, useCallback } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { StyledSpinnerNext } from "baseui/spinner";
import { Input } from "baseui/input";
import { Button } from "baseui/button";
import { Accordion, Panel } from "baseui/accordion";
import { Textarea } from "baseui/textarea";
import { Combobox } from "baseui/combobox";
import { toaster, ToasterContainer } from 'baseui/toast';
import Loading from "./loading";

export const Payment = ({ googleID, userEmail }) => {

  const [loading, setLoading] = useState(true);
  const [agentPaymentInfo, setAgentPaymentInfo] = useState(null);
  const [agentEmail, setAgentEmail] = useState(null);
  const [note, setNote] = useState(null);
  const [step, setStep] = useState(1);
  const [feeType, setFeeType] = useState("");
  const [fee, setFee] = useState(0);
  const [paymentSelected, setPaymentSelected] = useState(false);

  useEffect(() => {
    fetch(`https://xcro-343606.uw.r.appspot.com/payment?googleID=${googleID}`)
      .then(function (response) {
        // The response is a Response instance.
        // You parse the data into a useable format using `.json()`
        setLoading(false);
        return response.json();
      }).then(
        (result) => {
          setAgentPaymentInfo(result.agentPaymentInfo);
        },
        () => {
          const errorMessage = (
            <div>
                <p>Failed to load your agent info.</p>
                <p>Please try again.</p>
            </div>
          )
          toaster.negative(errorMessage);
        }
      )
  }, [googleID])

  const enterStep1 = useCallback(() => {
    setStep(1);
    setPaymentSelected(false);
  }, []);

  const enterStep2 = () => {
    setStep(2);
  };

  const validateEmail = () => {
    return agentEmail && agentEmail.includes("@");
  };

  const getNoteLimit = () => {
    if (note) {
      return (200 - note.length) + " characters left"; 
    }
    
    return "Max 200 characters";
  };

  const setPaymentType = (paymentSelection) => {
    setPaymentSelected(true);
    if (paymentSelection.includes("showing")) {
      setFee(agentPaymentInfo.showingFee);
      setFeeType("showingFee");
    } else if (paymentSelection.includes("inspection")) {
      setFee(agentPaymentInfo.inspectionFee);
      setFeeType("inspectionFee");
    } else if (paymentSelection.includes("offer")) {
      setFee(agentPaymentInfo.offerFee);
      setFeeType("offerFee");
    }
  }

  const findAgentPaymentInfo = useCallback(() => {
    fetch(`https://xcro-343606.uw.r.appspot.com/payment/search?paymentEmail=${agentEmail}`)
    .then(function (response) {
      // The response is a Response instance.
      // You parse the data into a useable format using `.json()`
      return response.json();
    }).then(
      (result) => {
        if (!result.agentPaymentInfo) {
          const message = (
            <div>
                <p>No agent info found.</p>
                <p>Please try a different email.</p>
            </div>
          )
          toaster.info(message);
        }
        setAgentPaymentInfo(result.agentPaymentInfo);
      },
      () => {
        const errorMessage = (
          <div>
              <p>Failed to find your agent info.</p>
              <p>Please try again.</p>
          </div>
        )
        toaster.negative(errorMessage);
      }
    )
  }, [agentEmail]);

  const validNote = (note) => {
    if (note.length > 200) {
      setNote(note.substr(0,200));
    } else {
      setNote(note);
    }
  };

  const savePayment = (paymentInfo) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "googleID": googleID,
        "userEmail": userEmail,
        "paymentEmail": agentPaymentInfo.paymentEmail,
        "transctionID": paymentInfo.id,
        "note": note,
        "paymentAmount": fee,
        "paymentType": feeType
      })
    };
    fetch('https://xcro-343606.uw.r.appspot.com/payment', requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(() => {
        toaster.info("Successfully saved payment info.");
      },
        () => {
          const errorMessage = (
            <div>
                <p>Failed to save your payment info.</p>
                <p>Please take a screenshot and send us the following info.</p>
                <p>{`GoogleID: ${googleID}`}</p>
                <p>{`UserEmail: ${userEmail}`}</p>
                <p>{`PaymentEmail: ${agentPaymentInfo.paymentEmail}`}</p>
                <p>{`TransctionID: ${paymentInfo.id}`}</p>
                <p>{`note: ${note}`}</p>
                <p>{`PaymentAmount: ${fee}`}</p>
                <p>{`PaymentType: ${feeType}`}</p>
            </div>
          )
          toaster.negative(errorMessage);
        });
  };

  // Custom component to wrap the PayPalButtons and handle currency changes
  const ButtonWrapper = ({ note,paymentEmail,fee,feeType }) => {
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
 
    return (
      <PayPalButtons
        forceReRender={[note,paymentEmail,fee,feeType]}
        createOrder={(data, actions) => {
          return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: fee,
                  },
                },
              ],
            })
            .then((orderId) => {
              // Your code here after create the order
              return orderId;
            });
        }}
        onApprove={function (data, actions) {
          return actions.order.capture().then(function (paymentInfo) {
            savePayment(paymentInfo);
          });
        }}
      />
    );
  }

  let page;
  if (loading) {
    page = <Loading />;
  } else if (step === 1) {
    if (!agentPaymentInfo) {
      const message = (
        <div className="left-text">
          <p>Find your agent's payment info by payment email.</p>
        </div>
      );

      page = (
        <div>
          {message}
          <Input
            key="find-agent"
            className="input"
            onChange={e => setAgentEmail(e.target.value)}
            placeholder="Enter an email..."
          />
          <Button className="button" onClick={findAgentPaymentInfo} disabled={!validateEmail()}>Find</Button>
          <Button className="button" onClick={enterStep2} disabled={!agentPaymentInfo}>Next</Button>
        </div>);
    } else {
      const message = (
        <div className="left-text">
          <p>The following email is your agent's email for final settlement payment:</p>
          <div className="padded-text">{agentPaymentInfo.paymentEmail}</div>
        </div>
      );

      page = (
        <div>
          {message}
          <Accordion
            accordion
            overrides={{
              Header: {
                style: ({ $theme }) => ({
                  outline: `${$theme.colors.primary300} solid`,
                  backgroundColor: $theme.colors.primary300
                })
              }
            }}
          >
            <Panel title="Use a different email">
              <p className="left-text">Find your agent's payment info by other payment email.</p>
              <Input
                key="email"
                className="input"
                onChange={e => setAgentEmail(e.target.value)}
                placeholder="Enter an email..."
              />
              <Button className="button" onClick={findAgentPaymentInfo} disabled={!validateEmail()}>Find</Button>
            </Panel>
          </Accordion>

          <Button className="button" onClick={enterStep2}>Next</Button>
        </div>);
    }
  } else {
    const message = (
      <div className="left-text">
        <p>The following email is your agent's email for final settlement payment:</p>
        <div className="padded-text">{agentPaymentInfo.paymentEmail}</div>
      </div>
    );

    let paymentButton;
    if (paymentSelected) {
      paymentButton = (
        <PayPalScriptProvider
          options={{
            "client-id": "AWUN1BWDYks--2DEIMbmDm5wTV-FkVEM7ZJJ6-oRAxpRAtGXtQlBaFTCU8MwsHCmy7uwgNWG7FZZnapg",
            "disable-funding": "card,credit",
            currency: "USD"
          }}
        >
          <ButtonWrapper
            note={note}
            paymentEmail={agentPaymentInfo.paymentEmail}
            fee={fee}
            feeType={feeType}
          />
        </PayPalScriptProvider>
      );
    } else {
      paymentButton = <Button className="button" disabled={true}>No payment type selected</Button>;
    }

    page = (
      <div>
        {message}
        <p className="left-align">Payment type:</p>
        <Combobox
          onChange={nextValue => setPaymentType(nextValue)}
          options={[
            { label: "$" + agentPaymentInfo.showingFee + " - showing fee", id: "showingFee" },
            { label: "$" + agentPaymentInfo.inspectionFee + " - inspection fee", id: "inspectionFee" },
            { label: "$" + agentPaymentInfo.offerFee + " - offer fee", id: "offerFee" }
          ]}
          mapOptionToString={option => option.label}
          overrides={{
            Input: {
              props: {
                placeholder: 'Choose a payment type',
              },
            },
          }}
        />
        <p className="left-align">Note:</p>
        <Textarea
          value={note}
          key="note"
          className="input"
          onChange={e => validNote(e.target.value)}
          placeholder="Enter a note..."
          overrides={{
            Input: {
              style: ({ $theme }) => ({
                height: "120px"
              })
            }
          }}
        />
        <div className="left-text small-font">{getNoteLimit()}</div>

        <Button className="button" onClick={enterStep1}>Back</Button>
        <p>By making an Xcro payment, you agree to enter an Xcro working agreement with your agent.</p>
        {paymentButton}
      </div>);
  }

  return (
    <div className="panel">
      <ToasterContainer />
      {page}
    </div>
  );
}